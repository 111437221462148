import axios from 'axios'
import auth from '../store/auth'

console.log(process.env.NODE_ENV)

export default () =>
  axios.create({
    // baseURL:
    //   process.env.NODE_ENV === 'production'
    //     ? 'https://admin.samsys.io/api/admin'
    //     : 'http://127.0.0.1:5001/admin',
    baseURL: 'https://admin.samsys.io/api/admin',
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `JWT ${auth.state.adminToken}`,
    },
  })
